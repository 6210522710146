.RenderingBigContainer {
  padding: 0 0 0 0 !important;
  background-color: #f5f7fb !important;
  overflow: hidden !important;
}

.Top_Big_Box {
  padding: 0 8px 0 8px !important;

  .Top_Containers {
    height: 165px;
    background-color: white !important;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.44) !important;
    padding: 0px 0 20px 0px !important;
    position: relative;

    .Top_Text {
      color: #9a9da2 !important;
      font-size: 15px !important;
    }

    .Top_Con_Main_Grid {
      height: 100%;

      .Top_Con_Image {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        img {
          height: 50px !important;
          border-radius: 50% !important;
          padding: 10px !important;
        }
      }

      .Top_Con_Content_one,
      .Top_Con_Content_two {
        gap: 10px !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
      }

      .Top_Con_Content_one {
        color: #9a9da2 !important;
      }

      .Top_Con_Link {
        position: absolute;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
        width: 100% !important;
        color: #0142da !important;
        font-size: 15px !important;
        cursor: pointer;
        bottom: 8px !important;
        font-family: "Poppins", sans-serif !important;
        font-weight: 600 !important;
        gap: 50px !important;

        img {
          height: 14px !important;
          padding-top: 2px !important;
          width: 60px !important;
        }
      }

      .Count-Value {
        font-size: 16px !important;
        font-weight: 600 !important;
        color: #3e3d3f !important;
      }
    }
  }
}

.Center_Big_Box {
  padding: 0 8px 0 8px !important;

  .Cheque_Status_Box {
    background-color: white !important;
    // background-color: white !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.44) !important;
    // padding: 10px 40px 10px 40px !important;
    padding: 24px !important;
    height: 240px !important;
    position: relative !important;

    .Cheque_Text {
      color: #9a9da2 !important;
      font-size: 15px !important;
    }

    .Cheque_box_Link {
      position: absolute !important;
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      text-align: center !important;
      width: 100% !important;
      color: #0142da !important;
      font-size: 15px !important;
      cursor: pointer;
      font-family: "Poppins", sans-serif !important;
      font-weight: 600 !important;
      gap: 50px;
      bottom: 10px !important;

      img {
        height: 14px !important;
        padding-top: 2px !important;
        width: 60px !important;
      }
    }
  }
}

.Footer_Big_box {
  padding: 0 8px 0 8px !important;

  .Footer_Box {
    background-color: white !important;
    // background-color: white !important;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.44) !important;
    padding: 10px 40px 10px 40px !important;
    height: 280px !important;
    position: relative;
  }

  .Footer_box_Link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
    width: 100% !important;
    color: #0142da !important;
    font-size: 15px !important;
    cursor: pointer;
    gap: 50px !important;
    position: absolute;
    bottom: 20px !important;

    img {
      height: 14px !important;
      padding-top: 2px !important;
      width: 60px !important;
    }
  }

  .Cheque_Text {
    color: #9a9da2 !important;
    font-size: 15px !important;
  }

  .Footer_second_Link {
    color: #0142da !important;
    font-size: 15px !important;
    display: flex !important;
    gap: 20px;

    img {
      height: 18px !important;
      padding-top: 8px !important;
      width: 60px !important;
    }
  }
}

.Dialogbox_left_li,
.Dialogbox_Right_li {
  width: 50% !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
}

.Dialogbox_Right_li {
  text-align: center !important;
}

.Dialogbox_left_li {
  padding-left: 10px !important;
}

// Added by Arun
.display-loader {
  position: absolute;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.remove-loader {
  display: none;
}

.Chart_Container {
  display: flex;
  align-items: center;
}

// text.MuiChartsAxis-tickLabel {
//   font-size: 7px !important;
// }
