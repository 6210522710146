@import "bootstrap/dist/css/bootstrap.min.css";
@import "react-toastify/dist/ReactToastify.css";
@import "/src/UiComponents/Scss/variables";
@import "/src/UiComponents/Scss/swal";

body {
  background-color: #f5f7fb;
  overflow: scroll !important;
  font-family: "Poppins", sans-serif !important;
}

body::-webkit-scrollbar {
  width: 0px !important;
}

.MainRenderinContainer {
  padding-top: 120px;
  padding-bottom: 80px;
}

.PageContainer {
  padding: 10px;
  height: 100% !important;
}

.form-control {
  border-radius: 0px;
}

.form-control:focus {
  box-shadow: none !important;
  outline: none;
  border-color: unset;
  border: 1px solid #00000045;
}

.DrawerHeader {
  padding: 10px 30px 10px 36px;
  border-bottom: 1px solid rgb(192, 190, 190);
  margin-bottom: 20px;
}

.DrawerFooter {
  position: absolute;
  // border-top: 1px solid rgb(192, 190, 190);
  width: 100%;
  bottom: 10px;
  right: 0;
  display: flex;
  justify-content: end;

  button:nth-child(1) {
    border: none;
    position: fixed;
    padding: 4px 10px 4px 10px;
    bottom: 10px !important;
    background-color: white;
    border-radius: 2px;
    right: 7% !important;
    width: 5% !important;
    border: 1px solid red;
    color: red;

    &:hover {
      background-color: red;
      color: white;
    }
  }

  button:nth-child(2) {
    border: none;
    position: fixed;
    bottom: 10px !important;
    padding: 4px 10px 4px 10px;
    background-color: $Button-Color;
    border-radius: 2px;
    width: 5% !important;
    color: white;
    border: 1px solid $Button-Color;
    margin-right: 10px;

    &:hover {
      background-color: white;
      color: $Button-Color;
      border: 1px solid $Button-Color;
    }
  }
}

//table pagination
.MuiTablePagination-toolbar {
  align-items: baseline !important;
}

.HeaderTittle {
  color: black !important;
  font-size: 22px !important;
}

.AntdSelect .ant-select-selector {
  border-radius: 0px !important;
}

//and rto mapping css
.ant-collapse-content-box {
  padding: 0 !important;
}

.errorMessage {
  font-weight: 600;
  font-size: 12px;
  color: red;
}

.DropdownField {
  border: none !important;

  .MuiOutlinedInput-root {
    border-radius: 0px !important;
    // padding: 0 0 0 9px !important;

    input {
      color: black !important;
      font-weight: 100 !important;
      font-size: 15px !important;
    }
  }
}

// number Input arrow removing css
.RemoveNumberFieldArrow {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }
}

.FilterIcon {
  color: white;
  background-color: #bdbdbd;
  padding: 6px;
  height: 36px !important;
  width: 36px !important;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  cursor: pointer;
}

.ActionIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.filterPaper {
  min-height: 20%;
  padding: 8px !important;
  height: 95% !important;
  width: 94%;
  box-shadow: none !important;
  position: absolute;
  overflow: scroll;
}

.filterPaper::-webkit-scrollbar {
  width: 0px !important;
}

.TableBox {
  background-color: white !important;
  border-radius: 4px !important;
  // border: 1px solid #b7becc;
  height: fit-content !important;
  overflow: scroll !important;
  // box-shadow: none !important;

  .css-1mxz8qt-MuiPaper-root {
    box-shadow: none !important;
    border: 1px solid #b7becc !important;
    border-radius: 0px !important;
  }
}

.TableBox::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.TableContainer {
  max-height: 600px !important;
  width: 100% !important;
}

.TableHeader {
  background-color: white !important;
  color: #747d8c !important;
  font-size: 15px !important;
  height: 60px !important;
  font-weight: 400 !important;
  font-family: "Poppins", sans-serif !important;
}

.OpenTable {
  width: 75% !important;
}

.OpenFilterBox {
  width: 25%;
  animation: slide 0.2s;
}

.CreateButton {
  background-color: $Nav-Color !important;
  color: white !important;
  border-radius: 2px !important;
}

.MuiInputBase-root {
  border-radius: 2px !important;
  height: 44px !important;
}

//tabel inner side button
.TabelButton {
  height: 43px !important;
  background-color: $Button-Color !important;
  border-radius: 2px !important;
  padding: 0 10px !important;
  color: white !important;
  // box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1) !important;
  outline: 0.1px solid rgb(149, 147, 147) !important;
  font-weight: 400 !important;
  text-transform: capitalize !important;

  .CSVbutton {
    text-decoration: none !important;
    color: white !important;
  }
}

.TabelButton:hover {
  color: white !important;
  background-color: $Button-Hover-Color !important;

  .CSVbutton {
    color: white !important;
  }
}

.TabelButton:active {
  background-color: $Button-Hover-Color !important;
  scale: 0.999 !important;
}

.Active_Container {
  display: flex;
  align-items: center;
  gap: 6px !important;

  & > div {
    height: 6px;
    width: 6px;
    background-color: green;
    border-radius: 50%;
  }

  .ActiveLabel {
    color: green !important;
  }
}

.InActive_Container {
  display: flex;
  align-items: center;
  gap: 6px !important;

  & > div {
    height: 6px;
    width: 6px;
    background-color: red;
    border-radius: 50%;
  }

  .InActiveLabel {
    color: red;
  }
}

.fileTitle {
  color: black;
  font-size: 14px;
  padding: 4px 0 0 2px;
}

.fileField {
  padding: 8px 0 0 100px !important;
}

.urlButton {
  height: 24px !important;
  background-color: green !important;
  border-radius: 2px !important;
  color: white !important;
  // outline: .1px solid black !important;
  font-weight: 100 !important;
  text-transform: capitalize !important;

  &:hover {
    background-color: rgb(5, 150, 228) !important;
  }
}

.EditPageHeadingTittle {
  font-size: 20px !important;
  color: $Button-Color !important;
  font-weight: 400 !important;
}

.File-Upload {
  .guZdik {
    max-width: 100% !important;
  }

  .sc-aXZVg {
    border: 1px solid #b7becc !important;
    height: 40px !important;
    border-radius: 3px !important;

    svg {
      display: block !important;
      // margin-left: 140px !important;
      height: 20px !important;
      width: 20px !important;
    }
  }
}

.CCEntryForm {
  width: 100%;

  .CCHeading {
    font-size: 16px !important;
    font-weight: 100 !important;
    color: gray !important;
    margin-bottom: 8px !important;
    width: 30% !important;
  }

  .CCContent {
    font-size: 16px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
    font-family: "Poppins", sans-serif !important;
    width: 60% !important;
  }

  .CCdivider {
    color: black !important;
    width: 10% !important;
  }
}

.AddNewButton {
  height: 44px !important;
  background-color: green !important;
  border-radius: 0px !important;
  color: white !important;
  width: 50px !important;
  min-width: 10px !important;
}

// dropdown add label css here {

.AddLabel {
  background-color: grey !important;
  width: 100%;
  color: white !important;
  display: flex !important;
  justify-content: start !important;
  padding-left: 16px !important;
}

.selectedLabel {
  width: 100%;
  display: flex !important;
  color: black !important;
  flex-direction: column !important;
  align-items: start !important;
  padding-left: 16px !important;
}

//end add label css

.OpenCalculationsBox {
  width: 100%;
}

.CloseCalculationsBox {
  width: 100%;
  animation: Closeslide 0.5s;
}

//Range Pickers date filed
.ant-picker-footer-extra {
  padding: 0 !important;
}

.Range_Picker {
  height: 40px;
  border-radius: 3px;

  .Date_Field {
    background-color: #f5f5f5 !important;
    text-align: center !important;
    cursor: pointer !important;

    &:hover {
      background-color: #1676fd !important;
      color: white !important;
    }
  }
}

.View_Icon {
  height: 32px !important;
}

.Edit_Icon {
  height: 38px !important;
}

.Delete_Icon {
  height: 36px !important;
}

.textField {
  height: 40px !important;
  border: 1px solid rgb(192, 190, 190) !important;
  outline: none !important;
  // padding-left: 10px !important;
  border-radius: 3px !important;

  .MuiOutlinedInput-root {
    border-radius: 3px !important;
  }

  input {
    height: 11px !important;
    border-radius: 0px !important;
  }
}

//--------New codes--------

.Master_Header_Container {
  padding: 10px 10px 20px 12px;
  display: flex !important;
  // justify-content: center !important;
  align-items: center !important;

  .Master_Header_Heading {
    font-family: "Poppins", sans-serif !important;
    font-weight: 600 !important;
    font-size: 18px !important;
  }

  .Master_Header_Input {
    border: 1px solid #949494 !important;
    border-radius: 5px !important;
    height: 40px !important;
    color: black !important;
    font-size: 14px !important;
    font-family: "Poppins", sans-serif !important;
  }

  .Master_Header_Filter {
    border: 1px solid #0142da !important;
    border-radius: 5px !important;
    height: 40px !important;

    .MuiOutlinedInput-root {
      padding: 0 0 0 10px !important;
      outline: none !important;
      border-color: transparent !important;
      color: #0142da !important;
      font-size: 14px !important;
      font-family: "Poppins", sans-serif !important;
      position: relative;

      //autocomplete dropdown arrow
      .MuiAutocomplete-popupIndicator {
        position: absolute;
        color: #0142da;
        cursor: pointer;
        right: 0px;
        top: -1px;
      }
    }
  }

  .Master_Header_create_Button {
    height: 40px !important;
    background-color: #0142da !important;
    color: white;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
  }

  .ActiveButton {
    background-color: white !important;
    color: #0142da !important;
    border: 1px solid #0142da !important;
  }

  .Master_Header_Reset_Button {
    height: 40px !important;
    background-color: red !important;
    color: white;
    font-size: 14px;
    font-family: "Poppins", sans-serif !important;
    text-transform: capitalize;
  }

  .Download_Excel_Button {
    color: white !important;
    text-decoration: none !important;
  }

  .Range_Picker {
    height: 41px !important;
    border-radius: 3px !important;
  }
}

.Common_Button {
  height: 40px !important;
  background-color: #0142da !important;
  color: white !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  text-transform: capitalize !important;
  border-radius: 3px !important;
  border: none !important;
}

.Date_Picker {
  height: 40px !important;
  border: 1px solid rgba(198, 202, 211, 1) !important;
  border-radius: 3px !important;
  // padding-bottom: 20px !important;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.MuiDialog-paper {
  width: 100% !important;
}

.AutoComplete_InputBox {
  border: 1px solid rgba(198, 202, 211, 1);
  width: 100%;
  background-color: white !important;
  height: 40px !important;
  border-radius: 3px !important;

  .ant-select-selector {
    border: none !important;
  }

  .MuiOutlinedInput-root {
    padding: 0 0 0 8px !important;
  }
}

// it is over all autocomplete color
.MuiOutlinedInput-root {
  color: #2b2d42 !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
}

.MuiAutocomplete-listbox {
  color: #2b2d42 !important;
  font-family: "Poppins" !important;
  font-size: 14px !important;
}

.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 6px !important;
  background-color: white !important;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  background-color: #838383 !important;
  border-radius: 10px !important;
}

.InputFiled {
  width: 100%;
  height: 40px !important;
  padding-left: 10px;
  border-radius: 3px !important;
  border: 1px solid rgba(198, 202, 211, 1) !important;
}

.InputFiled:focus {
  outline: none !important;
}

//below the code is all page count and total scss
.Count_Page {
  padding: 10px 10px 0 10px !important;
  width: 100% !important;
  display: flex;
  color: #2b2d42 !important;

  .Total {
    font-size: 34px !important;
    font-weight: 600 !important;
    font-family: "Poppins", sans-serif !important;
  }

  .Title {
    font-size: 14px !important;
    padding-left: 2px !important;
    font-family: "Poppins", sans-serif !important;
  }

  .Count_1,
  .Count_2,
  .Count_3 {
    height: 90px !important;
    padding: 20px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: start !important;
    border-radius: 3px !important;
  }

  .Count_1 {
    background-color: rgba(46, 213, 115, 0.13) !important;
  }

  .Count_2 {
    background-color: rgba(30, 144, 255, 0.15) !important;
  }

  .Count_3 {
    background-color: rgba(203, 105, 193, 0.12) !important;
  }
}

@keyframes Openslide {
  0% {
    transform: translateY(-180px);
  }

  100% {
    transform: skew(0deg);
  }
}

@keyframes Closeslide {
  0% {
    transform: translateX(100px);
  }

  100% {
    transform: skew(0deg);
  }
}

@keyframes slide {
  0% {
    transform: translateX(-100px);
  }

  100% {
    transform: skew(0deg);
  }
}

.settingsIcon {
  font-size: 26px !important;
}

@keyframes slides {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  .OpenFilterBox {
    width: 100% !important;
    height: 100% !important;
  }

  .OpenTable {
    display: none !important;
  }

  .filterBox {
    height: 560px !important;
  }

  .TableContainer {
    height: 560px;
    max-height: 560px !important;
  }
}

.MuiAccordionSummary-content > span.MuiTypography-root {
  font-weight: 600 !important;
  font-size: 13px !important;
}
